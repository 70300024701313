.custom-select .ant-select-selector,
.custom-range-picker {
  height: 100% !important;
  padding: 10px !important;
  background-color: #ffffff !important;
  border-color: #79747e !important;
}

.custom-select .ant-select-selector {
  padding: 12px 10px !important;
}

.custom-select2 .ant-select-selector {
  padding: 6px 10px !important;
}

.custom-select2 .ant-select-selector .ant-select-selection-search {
  inset-inline-start: 18px !important;
}

.custom-select2 .ant-select-selection-item,
.custom-select2 .ant-select-selection-placeholder {
  padding-left: 8px !important;
}

.custom-select2 .ant-select-selection-placeholder {
  color: #757575 !important;
}

.custom-select2 .ant-select-selection-search-input {
  font-family: Inter, Helvetica, 'sans-serif' !important;
  height: 40px !important;
}

.custom-select .ant-select-selector .ant-select-selection-placeholder {
  font-family: Inter, Helvetica, 'sans-serif' !important;
  font-weight: 500 !important;
  text-align: start;
}

.custom-range-picker {
  padding: 14px 10px !important;
}

.custom-range-picker .ant-picker-range-separator {
  line-height: 0 !important;
}

.custom-range-picker .ant-picker-input > input,
.custom-select .ant-select-selector .ant-select-selection-item {
  text-align: left;
  font-family: Inter, Helvetica, 'sans-serif' !important;
  font-weight: 500 !important;
}

.ant-select-item-option-content,
.ant-checkbox-wrapper {
  font-family: Inter, Helvetica, 'sans-serif' !important;
}

.ant-breadcrumb ol li {
  font-family: Inter, Helvetica, 'sans-serif' !important;
  color: #ff8682;
  font-weight: 500;
  font-size: 16px;
}

.image-modal .ant-modal-content {
  background-color: #ffffffe0;
  backdrop-filter: blur(3px);
}

.image-modal .ant-modal-close {
  top: 36px;
  inset-inline-end: 42px;
  color: #ffffff;
}

.image-modal .ant-modal-close:hover {
  color: #337ab7;
  background-color: transparent;
}

.image-modal .ant-modal-close .ant-modal-close-x {
  font-weight: 700;
  font-size: 26px;
}

.event-loader {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 400px !important;
}

.ant-switch-checked {
  background-color: var(--primary) !important;
}

.custom-datepicker,
.custom-datepicker-sm {
  width: 100%;
  background-color: var(--bs-gray-100);
  border-color: transparent;

  padding: 8px 14px;
  color: var(--bs-gray-700);
  .ant-picker-input {
    input {
      font-family: Inter, Helvetica, 'sans-serif' !important;
      font-weight: 500;
      font-size: 14px !important;
      color: var(--bs-gray-700) !important;
      &::placeholder {
        color: rgb(181 184 198) !important;
      }
    }
  }
}

.custom-datepicker-sm {
  border-radius: 0rem !important;
}

.custom-datepicker input {
  font-size: 16px !important;
}

.custom-datepicker input::placeholder {
  color: #79747e !important;
}

.custom-datepicker .ant-picker-separator {
  color: var(--bs-gray-700);
}

.ant-pagination.custom-pagination {
  .ant-pagination-item {
    font-family: Inter, Helvetica, 'sans-serif';
    font-weight: 500;
    font-size: 1.075rem;
    color: var(--bs-pagination-color);
  }
  .ant-pagination-item-link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--bs-pagination-color);
  }
  .ant-pagination-item-active {
    color: var(--bs-pagination-active-color);
    background-color: var(--bs-primary-active);
  }
  .ant-pagination-disabled .ant-pagination-item-link {
    color: var(--bs-gray-500);
  }
}

@media (max-width: 768px) {
  .ant-pagination.custom-pagination {
    margin-top: 1rem;
    margin-left: auto;
  }
}

.ant-table-wrapper.custom-table {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  .ant-table,
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: transparent;
  }
  .ant-table-cell {
    font-family: Inter, Helvetica, 'sans-serif';
    font-weight: 500;
    font-size: 14px;
    color: var(--bs-text-gray-600) !important;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    background-color: transparent;
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    border-bottom-color: var(--bs-border-color);
  }
  .ant-table-thead > tr > th {
    color: #b5b5cb !important;
    font-weight: 600;
    font-size: 12px;
    &::before {
      display: none;
    }
  }

  .ant-checkbox .ant-checkbox-inner {
    background-color: var(--bs-gray-200);
    border-color: var(--bs-gray-200);
  }
}

.custom-modal {
  font-family: Inter, Helvetica, 'sans-serif';
  h1,
  label {
    color: var(--bs-text-dark);
  }
  .ant-modal-header {
    background-color: var(--bs-body-bg);
  }
  .ant-modal-content {
    padding: 0px;
    background-color: var(--bs-body-bg);
    .ant-modal-close {
      top: 25px;
      color: var(--bs-text-muted);
    }
    .ant-modal-header {
      padding: 1.75rem 1.75rem;
      border-bottom: 1px solid var(--bs-border-color);
      .ant-modal-title {
        font-weight: 600;
        font-size: 1.5rem;
        color: var(--bs-text-dark);
      }
    }
    .ant-modal-footer {
      text-align: center;
      margin-top: 0px;
      padding: 1.75rem 1.75rem;
      border-top: 1px solid var(--bs-border-color);
      button {
        border: none;
        font-weight: 500;
        font-size: 15px;
        height: auto;
        padding: calc(0.65rem + 1px) calc(1.5rem + 1px);
        color: var(--bs-light-inverse);
        background-color: var(--bs-light);
      }
      .ant-btn-primary {
        color: #ffffff;
        background-color: var(--bs-primary-active);
      }
    }
  }
  .modal-body {
    color: var(--bs-text-dark);
  }
}

.ant-select.select-secondary {
  .ant-select-selector {
    font-size: 1.05rem;
    font-family: Inter, Helvetica, 'sans-serif';
    width: 100%;
    height: auto;
    padding: 0.35rem 0.75rem;
    font-weight: 500 !important;
    background-color: var(--bs-gray-100);
    border-color: var(--bs-gray-100);
    color: var(--bs-gray-700);
    height: 3.1rem !important;
    .ant-select-selection-search-input {
      height: 100%;
    }
  }

  .ant-select-selector:hover,
  .ant-select-selection:hover,
  .ant-select-selection-focused {
    border-color: var(--bs-gray-100) !important;
  }

  .ant-select-selection-placeholder {
    color: var(--bs-gray-500) !important;
  }

  .ant-select-arrow {
    color: #888 !important;
  }
}

//custom select with cursor pointer
.ant-select.selection-cursor-pointer {
  .ant-select-selector {
    cursor: pointer !important;
  }
}

ant-select.select-secondary.select-cursor-pointer {
  .ant-select-selector {
    cursor: pointer !important;
  }
}

.ant-select.select-secondary.select-sm {
  .ant-select-selector {
    border-radius: 0.35rem;
  }
}

.ant-select.select-secondary.select-lg {
  .ant-select-selector {
    min-height: 3.5rem;
    padding: 0.5rem 1.5rem;
    border-radius: 0.85rem;
    font-size: 1.11rem;
    font-weight: 500 !important;
  }
}

.ant-select-dropdown {
  background-color: var(--bs-light);
  .ant-select-item {
    color: var(--bs-gray-800);
  }
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  color: var(--bs-text-dark);
  font-weight: 500 !important;
}

.ant-picker-dropdown .ant-picker-time-panel-column::after {
  display: none !important;
}

/* Mobile Responsive Design */
@media (max-width: 767px) {
  .ads-loader {
    height: 150px !important;
  }
}
.ant-select-selection-item {
  font-weight: 500 !important;
}

//time rangepicker middle divider icon
.ant-picker-range .ant-picker-range-separator {
  padding-bottom: 0.75rem;
  padding-right: 3rem;
}

//custom weekly datepicker css
.ant-picker-cell-week {
  display: none !important;
}

.ant-picker-content th[aria-label='empty cell'] {
  display: none;
}

.ant-picker-dropdown .ant-picker-week-panel-row td:first-child:before {
  display: none !important;
}

.ant-picker-dropdown .ant-picker-week-panel-row-selected td.ant-picker-cell:nth-child(2):before {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

// date range picker styles
.custom-range-datepicker {
  width: 100%;
  background-color: var(--bs-gray-100);
  border-color: transparent;
  padding-top: 8px 14px;
  // padding-bottom: 8px;
  color: var(--bs-gray-700);
  .ant-picker-input {
    input {
      font-family: Inter, Helvetica, 'sans-serif' !important;
      font-weight: 500;
      font-size: 14px !important;
      color: var(--bs-gray-700) !important;
      &::placeholder {
        color: rgb(181 184 198) !important;
      }
    }
  }
}

.custom-range-datepicker input {
  font-size: 16px !important;
}

.custom-range-datepicker input::placeholder {
  color: #79747e !important;
}

.custom-range-datepicker .ant-picker-range-separator {
  color: var(--bs-gray-700);
  padding-bottom: 0px;
  width: 5%;
}

.custom-range-datepicker-sp-pt-6 .ant-picker-range-separator {
  padding-bottom: 6px;
  padding-top: 6px;
}

@media (max-width: 576px) {
  .ant-picker-panels {
    flex-direction: column;
  }
}
