:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000000;
  --bs-white: #ffffff;
  --bs-gray: #7E8299;
  --bs-gray-dark: #3F4254;
  --bs-text-muted: #A1A5B7;
  --bs-gray-100: #F9F9F9;
  --bs-gray-200: #F4F4F4;
  --bs-gray-300: #E1E3EA;
  --bs-gray-400: #B5B5C3;
  --bs-gray-500: #A1A5B7;
  --bs-gray-600: #7E8299;
  --bs-gray-700: #5E6278;
  --bs-gray-800: #3F4254;
  --bs-gray-900: #181C32;
  --bs-white: #ffffff;
  --bs-light: #F9F9F9;
  --bs-primary: #3E97FF;
  --bs-secondary: #E1E3EA;
  --bs-success: #50cd89;
  --bs-info: #7239ea;
  --bs-warning: #ffc700;
  --bs-danger: #f1416c;
  --bs-dark: #181C32;
  --bs-primary-active: #2884Ef;
  --bs-secondary-active: #B5B5C3;
  --bs-secondary-active: #B5B5C3;
  --bs-light-active: #F4F4F4;
  --bs-success-active: #47be7d;
  --bs-info-active: #5014d0;
  --bs-warning-active: #f1bc00;
  --bs-danger-active: #c9090d;
  --bs-dark-active: #131628;
  --bs-primary-light: #EEF6FF;
  --bs-secondary-light: #F9F9F9;
  --bs-success-light: #e8fff3;
  --bs-info-light: #f8f5ff;
  --bs-warning-light: #fff8dd;
  --bs-danger-light: #fff5f8;
  --bs-dark-light: #F4F4F4;
  --bs-primary-inverse: #FFFFFF;
  --bs-white-rgb: 255, 255, 255;
  --bs-light-rgb: 249, 249, 249;
  --bs-primary-rgb: 62, 151, 255;
  --bs-secondary-rgb: 225, 227, 234;
  --bs-success-rgb: 80, 205, 137;
  --bs-info-rgb: 114, 57, 234;
  --bs-warning-rgb: 255, 199, 0;
  --bs-danger-rgb: 241, 65, 108;
  --bs-dark-rgb: 24, 28, 50;
  --bs-primary-text: #0a58ca;
  --bs-secondary-text: #7E8299;
  --bs-success-text: #146c43;
  --bs-info-text: #087990;
  --bs-warning-text: #997404;
  --bs-danger-text: #b02a37;
  --bs-light-text: #7E8299;
  --bs-dark-text: #5E6278;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #F9F9F9;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfc;
  --bs-dark-bg-subtle: #B5B5C3;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #F4F4F4;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #F4F4F4;
  --bs-dark-border-subtle: #A1A5B7;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 24, 28, 50;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: Inter, Helvetica, "sans-serif";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #181C32;
  --bs-emphasis-color: #000000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(24, 28, 50, 0.75);
  --bs-secondary-color-rgb: 24, 28, 50;
  --bs-secondary-bg: #F4F4F4;
  --bs-secondary-bg-rgb: 244, 244, 244;
  --bs-tertiary-color: rgba(24, 28, 50, 0.5);
  --bs-tertiary-color-rgb: 24, 28, 50;
  --bs-tertiary-bg: #F9F9F9;
  --bs-tertiary-bg-rgb: 249, 249, 249;
  --bs-body-bg: #ffffff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-heading-color: #181C32;
  --bs-link-color: #3E97FF;
  --bs-link-color-rgb: 62, 151, 255;
  --bs-link-decoration: none;
  --bs-link-hover-color: #2884Ef;
  --bs-link-hover-color-rgb: 40, 132, 239;
  --bs-link-hover-decoration: none;
  --bs-code-color: #b93993;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #F4F4F4;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.75rem;
  --bs-border-radius-sm: 0.35rem;
  --bs-border-radius-lg: 0.85rem;
  --bs-border-radius-xl: 1.25rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-sm: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05);
  --bs-box-shadow-lg: 0 1rem 2rem 1rem rgba(0, 0, 0, 0.1);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-emphasis-color: #000000;
  --bs-form-control-bg: var(--bs-body-bg);
  --bs-form-control-disabled-bg: var(--bs-secondary-bg);
  --bs-highlight-bg: #fff3cd;
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1.075rem;
  --bs-pagination-color: var(--bs-gray-700);
  --bs-pagination-bg: transparent;
  --bs-pagination-border-width: 0;
  --bs-pagination-border-color: transparent;
  --bs-pagination-border-radius: 0.75rem;
  --bs-pagination-hover-color: var(--bs-component-hover-color);
  --bs-pagination-hover-bg: var(--bs-component-hover-bg);
  --bs-pagination-hover-border-color: transparent;
  --bs-pagination-focus-color: var(--bs-component-hover-color);
  --bs-pagination-focus-bg: var(--bs-component-hover-bg);
  --bs-pagination-focus-box-shadow: none;
  --bs-pagination-active-color: var(--bs-component-active-color);
  --bs-pagination-active-bg: var(--bs-component-active-bg);
  --bs-pagination-active-border-color: transparent;
  --bs-pagination-disabled-color: var(--bs-gray-400);
  --bs-pagination-disabled-bg: transparent;
  --bs-pagination-disabled-border-color: transparent;
}

[data-bs-theme=dark] {
  --bs-body-color: #FFFFFF;
  --bs-body-color-rgb: 255, 255, 255;
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000000;
  --bs-white: #ffffff;
  --bs-gray: #7E8299;
  --bs-gray-dark: #3F4254;
  --bs-text-muted: #A1A5B7;
  --bs-gray-100: #F9F9F9;
  --bs-gray-200: #F4F4F4;
  --bs-gray-300: #E1E3EA;
  --bs-gray-400: #B5B5C3;
  --bs-gray-500: #A1A5B7;
  --bs-gray-600: #7E8299;
  --bs-gray-700: #5E6278;
  --bs-gray-800: #3F4254;
  --bs-gray-900: #181C32;
  --bs-white: #ffffff;
  --bs-light: #F9F9F9;
  --bs-primary: #3E97FF;
  --bs-secondary: #E1E3EA;
  --bs-success: #50cd89;
  --bs-info: #7239ea;
  --bs-warning: #ffc700;
  --bs-danger: #f1416c;
  --bs-dark: #181C32;
  --bs-white-rgb: 255, 255, 255;
  --bs-light-rgb: 249, 249, 249;
  --bs-primary-rgb: 62, 151, 255;
  --bs-secondary-rgb: 225, 227, 234;
  --bs-success-rgb: 80, 205, 137;
  --bs-info-rgb: 114, 57, 234;
  --bs-warning-rgb: 255, 199, 0;
  --bs-danger-rgb: 241, 65, 108;
  --bs-dark-rgb: 24, 28, 50;
  --bs-primary-text: #0a58ca;
  --bs-secondary-text: #7E8299;
  --bs-success-text: #146c43;
  --bs-info-text: #087990;
  --bs-warning-text: #997404;
  --bs-danger-text: #b02a37;
  --bs-light-text: #7E8299;
  --bs-dark-text: #5E6278;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #F9F9F9;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfc;
  --bs-dark-bg-subtle: #B5B5C3;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #F4F4F4;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #F4F4F4;
  --bs-dark-border-subtle: #A1A5B7;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 24, 28, 50;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: Inter, Helvetica, "sans-serif";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-emphasis-color: #000000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(24, 28, 50, 0.75);
  --bs-secondary-color-rgb: 24, 28, 50;
  --bs-secondary-bg: #F4F4F4;
  --bs-secondary-bg-rgb: 244, 244, 244;
  --bs-tertiary-color: rgba(24, 28, 50, 0.5);
  --bs-tertiary-color-rgb: 24, 28, 50;
  --bs-tertiary-bg: #F9F9F9;
  --bs-tertiary-bg-rgb: 249, 249, 249;
  --bs-body-bg: #1e1e2d;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-heading-color: #181C32;
  --bs-link-color: #3E97FF;
  --bs-link-color-rgb: 62, 151, 255;
  --bs-link-decoration: none;
  --bs-link-hover-color: #2884Ef;
  --bs-link-hover-color-rgb: 40, 132, 239;
  --bs-link-hover-decoration: none;
  --bs-code-color: #b93993;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #F4F4F4;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.75rem;
  --bs-border-radius-sm: 0.35rem;
  --bs-border-radius-lg: 0.85rem;
  --bs-border-radius-xl: 1.25rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-sm: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05);
  --bs-box-shadow-lg: 0 1rem 2rem 1rem rgba(0, 0, 0, 0.1);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-emphasis-color: #000000;
  --bs-form-control-bg: var(--bs-body-bg);
  --bs-form-control-disabled-bg: var(--bs-secondary-bg);
  --bs-highlight-bg: #fff3cd;
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1.075rem;
  --bs-pagination-color: var(--bs-gray-700);
  --bs-pagination-bg: transparent;
  --bs-pagination-border-width: 0;
  --bs-pagination-border-color: transparent;
  --bs-pagination-border-radius: 0.75rem;
  --bs-pagination-hover-color: var(--bs-component-hover-color);
  --bs-pagination-hover-bg: var(--bs-component-hover-bg);
  --bs-pagination-hover-border-color: transparent;
  --bs-pagination-focus-color: var(--bs-component-hover-color);
  --bs-pagination-focus-bg: var(--bs-component-hover-bg);
  --bs-pagination-focus-box-shadow: none;
  --bs-pagination-active-color: var(--bs-component-active-color);
  --bs-pagination-active-bg: var(--bs-component-active-bg);
  --bs-pagination-active-border-color: transparent;
  --bs-pagination-disabled-color: var(--bs-gray-400);
  --bs-pagination-disabled-bg: transparent;
  --bs-pagination-disabled-border-color: transparent;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1.75rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 0;
  --bs-modal-border-radius: 0.75rem;
  --bs-modal-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  --bs-modal-inner-border-radius: 0.75rem;
  --bs-modal-header-padding-x: 1.75rem;
  --bs-modal-header-padding-y: 1.75rem;
  --bs-modal-header-padding: 1.75rem 1.75rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
}

*{
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
}

a{
    text-decoration: none !important;
}

input:focus,
button:focus{
    outline: none;
}

/* form error text color */
.ws-form-error-text {
  color: red
}

/* custom splash screen */
.ws-splash-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:var(--bs-body-bg); 
}

.ant-collapse-content-box {
  background: var(--bs-body-bg); 
}

.ws-temp-hide-for-mobile {
  display: none;
}

@media (min-width: 768px) {
  .ws-temp-hide-for-mobile {
    display: block;
  }
}