.header {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: var(--bs-app-bg-color);
  z-index: 999;
}

.layout {
  display: flex;
  position: relative;
  margin-top: 105px;
  .sidebar {
    width: 300px;
    overflow: hidden;
    transition: 0.3s;
    .app-sidebar {
      position: fixed;
      height: 90vh; //temp fix for works-schedule link not showings some screen sizes
    }
    &.sidebar-collapse {
      width: 105px;
      .menu-title {
        opacity: 0;
      }
      .menu-item {
        overflow: hidden;
        .menu-link {
          padding: 0px 20px;
          margin-bottom: 13px;
        }
      }
    }
  }

  //layout mobile view
  @media (max-width: 992px) {
    display: block;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .content {
    position: relative;
    width: 100%;
    margin-right: 80px;
    border-radius: 20px;
    background-color: var(--bs-app-content-bg-color);
    overflow: hidden;
    transition: 0.3s;
    z-index: 9;
  }
}

.auth-screen {
  // background: url("../img/background.png") no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  height: 100%;
}

.page-content {
  padding: 35px;
  min-height: 80vh;
}

.search-icon {
  font-size: 16px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.title {
  color: var(--bs-gray-800);
}

.title:hover {
  color: #3e97ff;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 100%;
}

.show-pass {
  position: absolute;
  top: 53%;
  transform: translateY(-50%);
  right: 13px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.otp-input {
  width: 60px !important;
  height: 60px;
  margin: 0.5rem 0.25rem;
  border: 1px solid #e1e3ea;
  font-size: 2rem;
  font-weight: 500;
  border-radius: 0.75rem;
}

/* Chrome, Safari, Edge, Opera */
.otp-input::-webkit-outer-spin-button,
.otp-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.otp-input[type='number'] {
  -moz-appearance: textfield;
}

.tab-btn {
  cursor: pointer;
}

.custom-calendar {
  .rbc-event {
    border: 1px solid #3e97ff;
    background-color: #3e97ff;
  }
  .rbc-header,
  .rbc-month-view,
  .rbc-day-bg + .rbc-day-bg,
  .rbc-month-row + .rbc-month-row,
  .rbc-time-view,
  .rbc-time-content > * + * > *,
  .rbc-timeslot-group,
  .rbc-time-header.rbc-overflowing,
  .rbc-day-slot .rbc-time-slot,
  .rbc-time-content,
  .rbc-time-header-content,
  .rbc-agenda-view table.rbc-agenda-table thead > tr > th,
  .rbc-agenda-view table.rbc-agenda-table,
  .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td,
  .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
    border-color: var(--bs-gray-200);
  }
  .rbc-row {
    cursor: pointer;
  }
  .rbc-header {
    padding: 0.75rem 0.5rem;
    span {
      display: inline-block;
      padding: 2px 4px;
      font-size: 1.1rem;
      font-weight: 500;
      color: var(--bs-gray-800);
    }
  }
  .rbc-off-range-bg {
    background-color: transparent;
    color: var(--bs-gray-800);
  }
  .rbc-toolbar {
    margin-bottom: 20px;
    button {
      font-size: 14px;
      padding: 10px 15px;
      font-weight: 500;
      color: var(--bs-gray-600);
      background-color: var(--bs-gray-100);
      border-color: var(--bs-gray-100);
      border-radius: 0px 8px 8px 0px;
      &:first-child {
        border-radius: 8px 0px 0px 8px;
      }
      &:last-child {
        border-radius: 8px;
      }
      &.rbc-active {
        box-shadow: none;
        color: var(--bs-gray-900);
        background-color: var(--bs-gray-200);
        border-color: var(--bs-gray-200);
      }
      &:hover {
        color: var(--bs-gray-700);
        transition: 0.3s;
        i {
          color: var(--bs-gray-700);
          transition: 0.3s;
        }
      }
      //disabled button
      &:disabled {
        color: var(--bs-gray-400);
        background-color: var(--bs-gray-100);
        border-color: var(--bs-gray-100);
        &:hover {
          color: var(--bs-gray-400);
          background-color: var(--bs-gray-100);
          border-color: var(--bs-gray-100);
          i {
            color: var(--bs-gray-400);
            transition: 0.3s;
          }
        }
        &:active {
          // remove box shadow
          box-shadow: none;
        }
      }
    }
    .rbc-btn-group:first-child {
      position: relative;
      width: 210px;
      button {
        &:first-child {
          position: absolute;
          right: 0px;
          border-radius: 8px;
        }
      }
      button {
        &:nth-child(2) {
          border-radius: 8px 0px 0px 8px;
        }
      }
    }
  }
  .rbc-time-view,
  .rbc-month-view {
    border-radius: 12px;
  }
  .rbc-today {
    background-color: var(--bs-success-light);
  }
  .rbc-toolbar-label {
    text-align: right;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--bs-gray-800);
  }
}

.swal2-popup {
  background-color: var(--bs-body-bg);
  padding: 2rem;
  border-radius: 0.75rem;
}

.swal2-icon.swal2-warning {
  border-color: var(--bs-warning);
  color: var(--bs-warning);
}

.swal2-container {
  .swal2-title {
    padding-top: 0px;
  }
  .swal-text {
    font-weight: 400;
    font-size: 1.1rem;
    margin-top: 1.5rem;
    color: var(--bs-gray-800);
  }
  .swal2-confirm {
    font-size: 1rem;
    padding: calc(0.775rem + 1px) calc(1.5rem + 1px);
    color: var(--bs-primary-inverse);
    border-color: var(--bs-primary);
    border-radius: 0.75rem;
    background-color: var(--bs-primary);
    box-shadow: none;
    &:focus {
      box-shadow: none;
    }
  }
  .swal2-cancel {
    font-size: 1rem;
    padding: calc(0.775rem + 1px) calc(1.5rem + 1px);
    color: var(--bs-text-dark);
    border-radius: 0.75rem;
    background-color: transparent;
  }
}

/* Media query for mobile devices */
@media (max-width: 300px) {
  .grid-container {
    grid-template-columns: 1fr;
  }

  .menu-item .menu-link {
    padding: 7px 12px;
  }

  .layout {
    margin-top: 60px;
    .sidebar {
      width: 90px;
      .menu-title {
        opacity: 0;
      }
      .menu-item {
        width: 45px;
        overflow: hidden;
      }
    }
    .content {
      margin-right: 20px;
    }
  }
  .app-sidebar-menu-primary.menu {
    padding: 0px 11px;
  }
}

@media (max-width: 600px) {
  .badge-hide-medium {
    display: none;
  }
  .page-content {
    padding: 10px;
  }
  .profile-dropdown .menu-item:hover .sub-menu-list {
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
  }
  .custom-calendar .rbc-toolbar-label {
    text-align: center;
    padding-top: 10px;
  }
  .form-switch {
    padding-left: 1.5em !important;
  }
  .otp-input {
    width: 40px !important;
    height: 40px !important;
  }
}

.apexcharts-canvas {
  position: relative;
  user-select: none;
}

.apexcharts-canvas ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
}

.apexcharts-canvas ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.apexcharts-inner {
  position: relative;
}

.apexcharts-text tspan {
  font-family: inherit;
}

.legend-mouseover-inactive {
  transition: 0.15s ease all;
  opacity: 0.2;
}

.apexcharts-series-collapsed {
  opacity: 0;
}

.apexcharts-tooltip {
  border-radius: 5px;
  box-shadow: 0px 0px 6px #999;
  cursor: default;
  font-size: 14px;
  left: 62px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  z-index: 12;
  transition: 0.15s ease all;
  border: 1px solid var(--bs-body-bg) !important;
  background: var(--bs-body-bg) !important;
}

.apexcharts-tooltip.apexcharts-active {
  opacity: 1;
  transition: 0.15s ease all;
}

.apexcharts-tooltip.apexcharts-theme-light {
  border: 1px solid #e3e3e3;
  background: rgba(255, 255, 255, 0.96);
}

.apexcharts-tooltip.apexcharts-theme-dark {
  color: #fff;
  background: rgba(30, 30, 30, 0.8);
}

.apexcharts-tooltip * {
  font-family: inherit;
}

.apexcharts-tooltip-title {
  border: 1px solid var(--bs-body-bg) !important;
  background: var(--bs-body-bg) !important;
  padding: 6px;
  font-size: 15px;
  margin-bottom: 4px;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: #eceff1;
  border-bottom: 1px solid #ddd;
}

.apexcharts-tooltip.apexcharts-theme-dark .apexcharts-tooltip-title {
  background: rgba(0, 0, 0, 0.7);
  border-bottom: 1px solid #333;
}

.apexcharts-tooltip-text-goals-value,
.apexcharts-tooltip-text-y-value,
.apexcharts-tooltip-text-z-value {
  display: inline-block;
  margin-left: 5px;
  font-weight: 600;
}

.apexcharts-tooltip-text-goals-label:empty,
.apexcharts-tooltip-text-goals-value:empty,
.apexcharts-tooltip-text-y-label:empty,
.apexcharts-tooltip-text-y-value:empty,
.apexcharts-tooltip-text-z-value:empty,
.apexcharts-tooltip-title:empty {
  display: none;
}

.apexcharts-tooltip-text-goals-label,
.apexcharts-tooltip-text-goals-value {
  padding: 6px 0 5px;
}

.apexcharts-tooltip-goals-group,
.apexcharts-tooltip-text-goals-label,
.apexcharts-tooltip-text-goals-value {
  display: flex;
}

.apexcharts-tooltip-text-goals-label:not(:empty),
.apexcharts-tooltip-text-goals-value:not(:empty) {
  margin-top: -6px;
}

.apexcharts-tooltip-marker {
  width: 12px;
  height: 12px;
  position: relative;
  top: 0;
  margin-right: 10px;
  border-radius: 50%;
}

.apexcharts-tooltip-series-group {
  padding: 0 10px;
  display: none;
  text-align: left;
  justify-content: left;
  align-items: center;
}

.apexcharts-tooltip-series-group.apexcharts-active .apexcharts-tooltip-marker {
  opacity: 1;
}

.apexcharts-tooltip-series-group.apexcharts-active,
.apexcharts-tooltip-series-group:last-child {
  padding-bottom: 4px;
}

.apexcharts-tooltip-series-group-hidden {
  opacity: 0;
  height: 0;
  line-height: 0;
  padding: 0 !important;
}

.apexcharts-tooltip-y-group {
  padding: 6px 0 5px;
}

.apexcharts-custom-tooltip,
.apexcharts-tooltip-box {
  padding: 4px 8px;
}

.apexcharts-tooltip-boxPlot {
  display: flex;
  flex-direction: column-reverse;
}

.apexcharts-tooltip-box > div {
  margin: 4px 0;
}

.apexcharts-tooltip-box span.value {
  font-weight: 700;
}

.apexcharts-tooltip-rangebar {
  padding: 5px 8px;
}

.apexcharts-tooltip-rangebar .category {
  font-weight: 600;
  color: #777;
}

.apexcharts-tooltip-rangebar .series-name {
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
}

.apexcharts-xaxistooltip,
.apexcharts-yaxistooltip {
  opacity: 0;
  pointer-events: none;
  color: #373d3f;
  font-size: 13px;
  text-align: center;
  border-radius: 2px;
  position: absolute;
  z-index: 10;
  background: #eceff1;
  border: 1px solid #90a4ae;
}

.apexcharts-xaxistooltip {
  padding: 9px 10px;
  transition: 0.15s ease all;
}

.apexcharts-xaxistooltip.apexcharts-theme-dark {
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: #fff;
}

.apexcharts-xaxistooltip:after,
.apexcharts-xaxistooltip:before {
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.apexcharts-xaxistooltip:after {
  border-color: transparent;
  border-width: 6px;
  margin-left: -6px;
}

.apexcharts-xaxistooltip:before {
  border-color: transparent;
  border-width: 7px;
  margin-left: -7px;
}

.apexcharts-xaxistooltip-bottom:after,
.apexcharts-xaxistooltip-bottom:before {
  bottom: 100%;
}

.apexcharts-xaxistooltip-top:after,
.apexcharts-xaxistooltip-top:before {
  top: 100%;
}

.apexcharts-xaxistooltip-bottom:after {
  border-bottom-color: #eceff1;
}

.apexcharts-xaxistooltip-bottom:before {
  border-bottom-color: #90a4ae;
}

.apexcharts-xaxistooltip-bottom.apexcharts-theme-dark:after,
.apexcharts-xaxistooltip-bottom.apexcharts-theme-dark:before {
  border-bottom-color: rgba(0, 0, 0, 0.5);
}

.apexcharts-xaxistooltip-top:after {
  border-top-color: #eceff1;
}

.apexcharts-xaxistooltip-top:before {
  border-top-color: #90a4ae;
}

.apexcharts-xaxistooltip-top.apexcharts-theme-dark:after,
.apexcharts-xaxistooltip-top.apexcharts-theme-dark:before {
  border-top-color: rgba(0, 0, 0, 0.5);
}

.apexcharts-xaxistooltip.apexcharts-active {
  opacity: 1;
  transition: 0.15s ease all;
}

.apexcharts-yaxistooltip {
  padding: 4px 10px;
}

.apexcharts-yaxistooltip.apexcharts-theme-dark {
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: #fff;
}

.apexcharts-yaxistooltip:after,
.apexcharts-yaxistooltip:before {
  top: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.apexcharts-yaxistooltip:after {
  border-color: transparent;
  border-width: 6px;
  margin-top: -6px;
}

.apexcharts-yaxistooltip:before {
  border-color: transparent;
  border-width: 7px;
  margin-top: -7px;
}

.apexcharts-yaxistooltip-left:after,
.apexcharts-yaxistooltip-left:before {
  left: 100%;
}

.apexcharts-yaxistooltip-right:after,
.apexcharts-yaxistooltip-right:before {
  right: 100%;
}

.apexcharts-yaxistooltip-left:after {
  border-left-color: #eceff1;
}

.apexcharts-yaxistooltip-left:before {
  border-left-color: #90a4ae;
}

.apexcharts-yaxistooltip-left.apexcharts-theme-dark:after,
.apexcharts-yaxistooltip-left.apexcharts-theme-dark:before {
  border-left-color: rgba(0, 0, 0, 0.5);
}

.apexcharts-yaxistooltip-right:after {
  border-right-color: #eceff1;
}

.apexcharts-yaxistooltip-right:before {
  border-right-color: #90a4ae;
}

.apexcharts-yaxistooltip-right.apexcharts-theme-dark:after,
.apexcharts-yaxistooltip-right.apexcharts-theme-dark:before {
  border-right-color: rgba(0, 0, 0, 0.5);
}

.apexcharts-yaxistooltip.apexcharts-active {
  opacity: 1;
}

.apexcharts-yaxistooltip-hidden {
  display: none;
}

.apexcharts-xcrosshairs,
.apexcharts-ycrosshairs {
  pointer-events: none;
  opacity: 0;
  transition: 0.15s ease all;
}

.apexcharts-xcrosshairs.apexcharts-active,
.apexcharts-ycrosshairs.apexcharts-active {
  opacity: 1;
  transition: 0.15s ease all;
}

.apexcharts-ycrosshairs-hidden {
  opacity: 0;
}

.apexcharts-selection-rect {
  cursor: move;
}

.svg_select_boundingRect,
.svg_select_points_rot {
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}

.apexcharts-selection-rect + g .svg_select_boundingRect,
.apexcharts-selection-rect + g .svg_select_points_rot {
  opacity: 0;
  visibility: hidden;
}

.apexcharts-selection-rect + g .svg_select_points_l,
.apexcharts-selection-rect + g .svg_select_points_r {
  cursor: ew-resize;
  opacity: 1;
  visibility: visible;
}

.svg_select_points {
  fill: #efefef;
  stroke: #333;
  rx: 2;
}

.apexcharts-svg.apexcharts-zoomable.hovering-zoom {
  cursor: crosshair;
}

.apexcharts-svg.apexcharts-zoomable.hovering-pan {
  cursor: move;
}

.apexcharts-menu-icon,
.apexcharts-pan-icon,
.apexcharts-reset-icon,
.apexcharts-selection-icon,
.apexcharts-toolbar-custom-icon,
.apexcharts-zoom-icon,
.apexcharts-zoomin-icon,
.apexcharts-zoomout-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  line-height: 24px;
  color: #6e8192;
  text-align: center;
}

.apexcharts-menu-icon svg,
.apexcharts-reset-icon svg,
.apexcharts-zoom-icon svg,
.apexcharts-zoomin-icon svg,
.apexcharts-zoomout-icon svg {
  fill: #6e8192;
}

.apexcharts-selection-icon svg {
  fill: #444;
  transform: scale(0.76);
}

.apexcharts-theme-dark .apexcharts-menu-icon svg,
.apexcharts-theme-dark .apexcharts-pan-icon svg,
.apexcharts-theme-dark .apexcharts-reset-icon svg,
.apexcharts-theme-dark .apexcharts-selection-icon svg,
.apexcharts-theme-dark .apexcharts-toolbar-custom-icon svg,
.apexcharts-theme-dark .apexcharts-zoom-icon svg,
.apexcharts-theme-dark .apexcharts-zoomin-icon svg,
.apexcharts-theme-dark .apexcharts-zoomout-icon svg {
  fill: #f3f4f5;
}

.apexcharts-canvas .apexcharts-reset-zoom-icon.apexcharts-selected svg,
.apexcharts-canvas .apexcharts-selection-icon.apexcharts-selected svg,
.apexcharts-canvas .apexcharts-zoom-icon.apexcharts-selected svg {
  fill: #008ffb;
}

.apexcharts-theme-light .apexcharts-menu-icon:hover svg,
.apexcharts-theme-light .apexcharts-reset-icon:hover svg,
.apexcharts-theme-light .apexcharts-selection-icon:not(.apexcharts-selected):hover svg,
.apexcharts-theme-light .apexcharts-zoom-icon:not(.apexcharts-selected):hover svg,
.apexcharts-theme-light .apexcharts-zoomin-icon:hover svg,
.apexcharts-theme-light .apexcharts-zoomout-icon:hover svg {
  fill: #333;
}

.apexcharts-menu-icon,
.apexcharts-selection-icon {
  position: relative;
}

.apexcharts-reset-icon {
  margin-left: 5px;
}

.apexcharts-menu-icon,
.apexcharts-reset-icon,
.apexcharts-zoom-icon {
  transform: scale(0.85);
}

.apexcharts-zoomin-icon,
.apexcharts-zoomout-icon {
  transform: scale(0.7);
}

.apexcharts-zoomout-icon {
  margin-right: 3px;
}

.apexcharts-pan-icon {
  transform: scale(0.62);
  position: relative;
  left: 1px;
  top: 0;
}

.apexcharts-pan-icon svg {
  fill: #fff;
  stroke: #6e8192;
  stroke-width: 2;
}

.apexcharts-pan-icon.apexcharts-selected svg {
  stroke: #008ffb;
}

.apexcharts-pan-icon:not(.apexcharts-selected):hover svg {
  stroke: #333;
}

.apexcharts-toolbar {
  position: absolute;
  z-index: 11;
  max-width: 176px;
  text-align: right;
  border-radius: 3px;
  padding: 0 6px 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.apexcharts-menu {
  background: #fff;
  position: absolute;
  top: 100%;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 3px;
  right: 10px;
  opacity: 0;
  min-width: 110px;
  transition: 0.15s ease all;
  pointer-events: none;
}

.apexcharts-menu.apexcharts-menu-open {
  opacity: 1;
  pointer-events: all;
  transition: 0.15s ease all;
}

.apexcharts-menu-item {
  padding: 6px 7px;
  font-size: 12px;
  cursor: pointer;
}

.apexcharts-theme-light .apexcharts-menu-item:hover {
  background: #eee;
}

.apexcharts-theme-dark .apexcharts-menu {
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
}

@media screen and (min-width: 768px) {
  .apexcharts-canvas:hover .apexcharts-toolbar {
    opacity: 1;
  }
}
.apexcharts-canvas .apexcharts-element-hidden,
.apexcharts-datalabel.apexcharts-element-hidden,
.apexcharts-hide .apexcharts-series-points {
  opacity: 0;
}

.apexcharts-datalabel,
.apexcharts-datalabel-label,
.apexcharts-datalabel-value,
.apexcharts-datalabels,
.apexcharts-pie-label {
  cursor: default;
  pointer-events: none;
}

.apexcharts-pie-label-delay {
  opacity: 0;
  animation-name: opaque;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
}

.apexcharts-annotation-rect,
.apexcharts-area-series .apexcharts-area,
.apexcharts-area-series .apexcharts-series-markers .apexcharts-marker.no-pointer-events,
.apexcharts-gridline,
.apexcharts-line,
.apexcharts-line-series .apexcharts-series-markers .apexcharts-marker.no-pointer-events,
.apexcharts-point-annotation-label,
.apexcharts-radar-series path,
.apexcharts-radar-series polygon,
.apexcharts-toolbar svg,
.apexcharts-tooltip .apexcharts-marker,
.apexcharts-xaxis-annotation-label,
.apexcharts-yaxis-annotation-label,
.apexcharts-zoom-rect {
  pointer-events: none;
}

.apexcharts-marker {
  transition: 0.15s ease all;
}

.calendar-day-item {
  cursor: pointer;
}

.calendar-day-item:hover {
  cursor: pointer;
  background-color: var(--bs-light);
}

.table-dark-color-cell {
  padding: 5px;
  border-radius: 10px;
  text-align: center;
  color: #ffffff;
  width: 100px;
}

.table-light-color-cell {
  padding: 5px;
  border-radius: 10px;
  text-align: center;
  color: var(--bs-text-gray-800);
  width: 100px;
}

.custom-nexted-table {
  background-color: var(--bs-light);
  padding: 24px;
  border-radius: 16px;
}

.report-top-bar-inputs {
  display: flex;
  flex-direction: column;
  gap: 20;
  width: 100%;
}

.task-est-input-prefix {
  display: flex;
  align-items: center;
  padding: 0.775rem 0.5rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: var(--bs-gray-700);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-gray-100);
  border: none !important;
  border-radius: 0px 6px 6px 0px !important;
  width: 45px;
  justify-content: center;
}

.task-est-input {
  border-radius: 6px 0px 0px 6px !important;
  padding: 0.775rem 0.6rem;
  width: 55px;
}

.days-input {
  width: 59px;
}

// worktime user selector
@media (max-width: 767px) {
  .worktime-user-selector {
    flex-direction: column; /* Set flex-direction to column on small screens */
  }
}

@media (min-width: 768px) {
  .worktime-user-selector {
    flex-direction: row; /* Set flex-direction to row on larger screens */
  }
}

// mobile view
@media (max-width: 992px) {
  .data-import-form {
    margin-left: 3rem;
  }
  .task-est-input-prefix {
    width: 35px;
  }
  .task-est-input {
    width: 45px;
  }
  .days-input {
    width: 49px;
  }
  
}

.gm-ui-hover-effect { display: none !important }